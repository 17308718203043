<template>
  <add-item
      :itemObject="item"
      :baseUrl="baseUrl"
      :submitBtnTitle="submitBtnTitle"
      :title="title"
  />
</template>

<script>
import AddItem from './../components/AddItem'

export default {
  components: {
    AddItem
  },
  data() {
    return {
      submitBtnTitle: this.$t('messages.itemCreate'),
      title: this.$t('messages.itemCreate'),
      baseUrl: '/others',
      item: {
        itemCategorySelect: {
          label: this.$helper.getEnumTranslation("ITEM_CATEGORY", 'OTHER', this.$i18n.locale),
          id: 'OTHER'
        }
      }
    }
  },
}
</script>
